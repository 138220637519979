import '@assets/scss/pages/legal.scss';

import React from 'react';
import Helmet from 'react-helmet';
import useHover from '@assets/scripts/hooks/useHover';

// components
import Layout from '@src/layouts';
import HeroArticle from '@components/molecules/heros/HeroArticle';
// import Link from '@components/atoms/LinkPage';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-legal';

const footerRelatedLinks = [
	{ title: 'Conditions Générales d’Utilisation', url: '/fr/conditions/' },
];

// --------------------------------
// #endregion
// --------------------------------

const PrivacyPolicyPage = (props) => {
	// interaction
	const [hoverOn, hoverOff] = useHover();

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/en/privacy-policy/', lang: 'en' }]}
			title="Politique de Confidentialité - Superhuit"
		>
			<Helmet
				bodyAttributes={{
					class: `page ${pageClass}`,
				}}
			/>
			<HeroArticle title={'Politique de Confidentialité'} />
			<div className="wrapper">
				<section
					className="grid-content grid text-content"
					data-animation-page
				>
					<div className="headnote">
						<p>
							<b>
								Cette page est pour le moment uniquement
								disponible en anglais. Merci de votre
								compréhension.
							</b>
						</p>
					</div>
					<ol type="number">
						<li>
							<h2>Preamble and Consent</h2>
							<p>
								The present Website Privacy Policy (“
								<b>Policy</b>”) governs your relationship with
								the superhuit.ch website (the “<b>Website</b>”)
								and our dedicated pages on the following social
								media and platforms: Facebook, Twitter,
								Instagram, YouTube, Behance, Vimeo and LinkedIn,
								all operated by SUPERHUIT and its affiliate(s)
								(“
								<b>SUPERHUIT</b>”, “<b>us</b>”, “<b>we</b>”, “
								<b>our</b>”), which main office is located in
								Rue des Terreaux 7, 1003 Lausanne.
							</p>

							<p>
								Please read carefully this Policy, which
								explains how your personal data will be
								collected, used and protected, before using the
								Website and our dedicated pages on the social
								media and platforms. Your access and use of the
								Website and social media pages are conditional
								upon your acceptance of, and compliance with
								this Policy.
							</p>

							<p>
								<b>
									By browsing our Website, you agree to be
									bound by this Policy and you agree and
									consent to the collection, use and
									processing of Personal Data in accordance
									with this Policy. If you do not consent to
									the terms of this Privacy Policy, we kindly
									ask you not to use our Website or dedicated
									pages on social media.
								</b>
							</p>

							<p>
								Please also note that you have the right to
								withdraw your consent at any time, it being
								however specified that such withdrawal:
							</p>
							<ul>
								<li>
									will not affect the lawfulness of processing
									based on your consent before its withdrawal;
								</li>
								<li>
									may prevent you from using some features of
									our Website or dedicated pages on social
									media.
								</li>
							</ul>
						</li>
						<li>
							<h2>Data Protection Officer</h2>
							<p>
								Please do not hesitate to contact us at
								<a
									href="mailto:legal@superhuit.ch"
									data-target
									onMouseEnter={() => hoverOn()}
									onMouseLeave={() => hoverOff()}
								>
									{' '}
									legal@superhuit.ch{' '}
								</a>
								should you have any questions. We would be happy
								to answer any query.
							</p>
						</li>
						<li>
							<h2>General Principles</h2>
							<ol type="number-item">
								<li>
									Our Website and social media platforms
									contain the following information and
									provides the following services:
									<ol type="lower-roman">
										<li>
											information about the services
											offered by our organization, its
											affiliates and members,
										</li>
										<li>
											various channels to get in touch
											with us (contact forms, links to
											social media account, etc.),
										</li>
										<li>
											information on conferences and other
											events,
										</li>
										<li>
											links to other websites and social
											media platforms,
										</li>
										<li>
											information about vacant positions
											and a related application form.
										</li>
									</ol>
								</li>
								<li>
									This Privacy Policy lays out our policies
									and procedures surrounding the collection
									and processing of any information that
									identifies you (i.e. “Personal Data”), which
									you may for instance provide to us when
									visiting our Website or social media
									platforms or using one of the services
									described under 3.1.
								</li>
								<li>
									This Privacy Policy applies only to our
									Website and our dedicated pages on social
									media. Please further note that if you
									access to our dedicated pages on social
									media, information you provide will also be
									subject to the privacy policy of operator of
									the social media platform.
								</li>
								<li>
									This Privacy Policy does not apply to any
									offline services offered by our
									organization.
								</li>
							</ol>
						</li>
						<li>
							<h2>
								Personal Data we collect, and why we collect
								them
							</h2>
							<h3>A. Sources of Collection</h3>
							<ol type="number-item">
								<li>
									Data we collect via our website and social
									media are divided in three main categories:
									<ol type="lower-roman">
										<li>
											cookies, analytics and other similar
											tools (below B.),
										</li>
										<li>
											personal Data you provide to us
											(below C),
										</li>
										<li>
											Data from other sources (below D).
										</li>
									</ol>
								</li>
							</ol>
							<h3>
								B. Cookies, Analytics and other similar tools
							</h3>
							<h4>
								a) What are Cookies and what information they
								collect?
							</h4>
							<ol type="number-item">
								<li>
									Cookies are files with small amount of data
									which are sent to your browser from a
									website and stored on your device (computer
									or mobile device). Tracking technologies
									also used are scripts to collect and track
									information and to improve and analyze our
									Website.
								</li>
								<li>
									Through cookies and other tracking
									technologies, we may collect Personal Data
									from you when you visit our Website or
									dedicated pages on social media. We may log
									in particular usage data when you view or
									click on content.
								</li>
								<li>
									The following information and Personal Data
									are collected through the cookies and other
									tracking technologies we use:
									<ol type="lower-roman">
										<li>
											IP address of the requesting
											internet-enabled device,
										</li>
										<li>
											location of the requesting
											internet-enabled device based on the
											IP address,
										</li>
										<li>date and time of access,</li>
										<li>
											accessed resources on the Website,
										</li>
										<li>
											name and URL of the retrieved file,
										</li>
										<li>
											website/application from which the
											access was made (referrer URL).
										</li>
									</ol>
								</li>
								<li>
									The following information and Personal Data
									are collected through your web browser:
									<ol type="lower-roman">
										<li>browser you use,</li>
										<li>
											if necessary, the operating system
											of your internet-capable computer as
											well as the name of your access
											provider.
										</li>
									</ol>
								</li>
								<li>
									In order to control/limit the collection of
									this information, you can:
									<ol type="lower-roman">
										<li>
											if available, set up your browser to
											refuse all cookies or to indicate
											when a cookie is being sent,
										</li>
										<li>
											when reaching our Website, select
											what cookie you allow and what
											cookie you want to block,
										</li>
										<li>
											delete cookies and clear your
											browsing data directly from your
											browser’s settings.
										</li>
									</ol>
									<p>
										However, please note that if you do not
										accept certain cookies, you may not be
										able to use some features of our
										Website.
									</p>
								</li>
								<li>
									List of the Cookies and Scripts we use:
									<ol type="lower-roman">
										{/* <li>
											Session Cookies and Scripts: Cookies
											and Scripts used to operate our
											Website, such as:
											<ul>
												<li>
													Adobe Typekit which enables
													to upload certain fonts on
													our Website but does not
													place any cookie;
												</li>
												<li>
													PPL Language which enables
													to provide the Website in
													the same language as the one
													chose for your browser;
												</li>
												<li>
													Supercitron which enables to
													ask and save your cookies
													settings the Website without
													tracking.
												</li>
											</ul>
										</li> */}
										<li>
											Essential Cookies: Cookies used to
											operate our Website, such as:
											<li>
												Cookies that store your Cookie
												Settings of this Website.
											</li>
										</li>
										{/* <li>
											Security Cookies: Cookies used for
											security purposes, such as antispam
											protection, such as:
											<ul>
												<li>
													Recaptcha which is an
													antispam on our contact
													forms.
												</li>
											</ul>
										</li> */}
										{/* <li>
											External Services Cookies: Cookies
											used for interactive services such
											as our blog, such as:
											<ul>
												<li>
													Disqus which enables to
													comment our articles.
												</li>
											</ul>
										</li> */}
										<li>
											Analytics Cookies: Cookies used to
											collect standard internet log
											information and details of visitor
											behavior patterns, such as:
											<ul>
												<li>
													Google Analytics which
													enables us to find out such
													things as the number of
													visitors to the various
													parts of the Website and
													monitor the Website traffic,
													to identify any service
													issues, improve our
													services, and generate
													statistics.
												</li>
												<li>
													Google Tag Manager which is
													a Google tool relating to
													Google Analytics enabling
													customized tracking.
												</li>
												<li>
													Google Doubleclick which is
													a Google tool relating to
													Google Analytics enabling
													Google to provide customized
													advertising.
												</li>
												{/* <li>
													Facebook pixel which enables
													us to operate behavioral
													retargeting based on your
													Facebook account if you are
													connected to the latter
													while browsing our Website.
												</li>
												<li>
													Automation which enables to
													find out the user’s behavior
													on our Website in order to
													adapt the content of the
													email we send him/her.
												</li> */}
											</ul>
										</li>
									</ol>
								</li>
							</ol>
							<h4>
								b) Why we collect information through cookies
								(purpose)?
							</h4>
							<ol type="number-item">
								<li>
									We use cookies and the Personal Data
									collected:
									<ol type="lower-roman">
										<li>
											to authorize access to our Website,
										</li>
										<li>
											to make the functionalities of our
											Website available to you and to
											offer you additional
											functionalities,
										</li>
										<li>
											to improve the Website or our
											dedicated pages on social media,
										</li>
										<li>
											to store information about your
											preferences, allowing us to
											customize our Website according to
											your individual interests,
										</li>
										<li>to speed up your searches,</li>
										<li>
											to compile behavioral and
											statistical information about uses
											of our Website or our dedicated
											pages on social media, in particular
											to estimate our audience size and
											usage patterns,
										</li>
										<li>
											to produce aggregate insights that
											do not identify you,
										</li>
										<li>
											to identify you and log your use,
											recognize you when you return to our
											Website, track the activity on our
											Website and hold certain
											information.
										</li>
									</ol>
								</li>
								<li>
									We make sure that the Personal Data
									collected through Google Analytics, Google
									Tag Manager, Google Doubleclick
									{/* and Facebook
									pixel*/} do not identify
									anyone even when they are transferred to
									third party such as Google{/* or Facebook*/}
									; for information about the processing and
									use of the data by Google or Facebook, as
									well as related rights and settings options
									for the protection of your privacy, please
									refer to the privacy policy of the selected
									provider.
								</li>
							</ol>
							<h4>c) Legal basis for processing</h4>
							<ol type="number-item">
								<li>
									The purpose for implementing all of the
									above is to maintain and monitor the
									performance of our Website and to constantly
									look to improve the site and the services it
									offers to our users. The legal basis we rely
									on to process your personal data is article
									6(1)(f) of the GDPR, which allows us to
									process personal data when its necessary for
									the purposes of our legitimate interests.
								</li>
								<li>
									In addition, cookies and other
									functionalities mentioned above which would
									go beyond the purposes of our legitimate
									interests (for example: Analytics Cookies),
									are implemented upon your acceptance through
									the means of our cookie banner.
								</li>
							</ol>
							<h3>C. Personal Data you provide us</h3>
							<h4>a) What Personal Data you provide us?</h4>
							<ol type="number-item">
								<li>
									We collect Personal Data offered by you
									voluntarily when, on our Website, you:
									<ol type="lower-roman">
										<li>fill out a contact form,</li>
										<li>fill out an application form.</li>
									</ol>
								</li>
								<li>
									These data may include your names, employer,
									e-mail address, telephone number, as well as
									any data that can be found on your resume,
									such as title, gender, date of birth,
									nationality, marital status, picture,
									personal and business addresses, country of
									residence, professional details and
									qualification records.
								</li>
							</ol>
							<h4>
								b) Why we collect such Personal Data (purpose)?
							</h4>
							<ol type="number-item">
								<li>
									We use the Personal Data you provide us with
									for the following purposes:
									<ol type="lower-roman">
										<li>
											to allow you to participate in
											interactive features of the Website
											when you choose to do so,
										</li>
										<li>to provide customer support,</li>
										<li>to assess your application,</li>
										<li>
											to fulfill the purposes for which
											you provide the data.
										</li>
									</ol>
								</li>
							</ol>
							<h4>c) Legal basis for processing</h4>
							<ol type="number-item">
								<li>
									The legal basis we rely on to process the
									Personal Data you provide us is article
									6(1)(a) of the GDPR, which allows us to
									process personal data when the data subject
									has given consent to the processing of his
									or her personal data.
								</li>
							</ol>
							<h3>
								D. Personal data collected about you from other
								sources
							</h3>
							<h4>
								a) What information we collect from other
								sources?
							</h4>
							<ol type="number-item">
								<li>
									We may collect Personal Data about you
									available on Facebook if you are logged onto
									your account while you are browsing our
									Website.
								</li>
							</ol>
							<h4>
								b) Why we collect information through these
								other sources?
							</h4>
							<ol type="number-item">
								<li>
									We collect and use Personal Data about you
									available on Facebook to make behavioral
									retargeting, in a way that does not identify
									anyone even when they are transferred to
									Facebook; for information about the
									processing and use of the data by Google or
									Facebook, as well as related rights and
									settings options for the protection of your
									privacy, please refer to the privacy policy
									of the selected provider.
								</li>
							</ol>
							<h4>c) Legal basis for processing</h4>
							<ol type="number-item">
								<li>
									The legal basis for collecting your Personal
									Data from the abovementioned sources is your
									acceptance through the means of our cookie
									banner, in particular the Facebook pixel
									cookie. The legal basis we rely on to
									process your personal data is article
									6(1)(a) of the GDPR.
								</li>
							</ol>
						</li>
						<li>
							<h2>
								Personal data collected by third parties (Link
								to other websites and social media)
							</h2>
							<ol type="number-item">
								<li>
									Where we provide links to websites of other
									organizations, this privacy policy does not
									cover how that organization processes your
									Personal Data. We encourage you to read the
									privacy policies related to the other
									websites you visit.
								</li>
								<li>
									Our Website and our dedicated pages on
									social media contain links that direct you
									to the social networks such as Facebook,
									Twitter, Instagram, YouTube, Behance, Vimeo
									and LinkedIn (the “Social network”) or to
									another website, in order to connect you
									with the content displayed in these
									platforms. When you access a Social network
									or another website via our Website or one of
									our dedicated pages on social media,
									responsibility for compliance with data
									protection is to be guaranteed by the
									respective providers.
								</li>
								<li>
									If you establish a connection with the
									Social network of your choice or another
									website, your browser connects directly to
									the provider’s servers. Through this
									integration, the Social network or the other
									website receives the information that your
									browser previously accessed the
									corresponding page of our Website or our
									dedicated page on a social media, even if
									you do not have a profile or are currently
									not logged in to your account. This
									information (including your IP address) will
									be transmitted from your browser directly to
									a provider’s server and stored there. If you
									are logged in to your account, the provider
									can immediately assign the visit to our
									Website or our dedicated page on a social
									media to your profile. If you interact with
									the Website or our dedicated page on a
									social media, for example by clicking a
									“Like” or “Share” button, this information
									will also be transmitted directly to the
									provider’s server and stored there
								</li>
								<li>
									For information about the purpose and scope
									of the data collection and the further
									processing and use of the data by the
									requested Social network or the other
									website, as well as related rights and
									settings options for the protection of your
									privacy, please refer to the privacy policy
									of the selected provider.
								</li>
							</ol>
						</li>
						<li>
							<h2>
								Sharing your personal data with third parties
							</h2>
							<ol type="number-item">
								<li>
									As noted above, we may compile usage
									statistics relating to our Website or our
									dedicated pages on social media based
									notably on your Personal Data.
								</li>
								<li>
									Your Personal Data may be transferred to a
									buyer or other successor in the event of a
									merger, divestiture, restructuring,
									reorganization, dissolution or other sale or
									transfer of some or all of SUPERHUIT’s
									assets, whether as a going concern or as
									part of bankruptcy, liquidation or similar
									proceeding, in which personal information
									held by SUPERHUIT about our Website users is
									among the assets transferred.
								</li>
								<li>
									We may give certain independent contractors
									and affiliates access to the Personal Data
									you made available to us through our Website
									or our dedicated pages on social media in
									order to assist us with the operation of our
									Website or our dedicated pages on social
									media, as well as data management and
									marketing activities. To date, those
									contractors are the following:
									<ol type="lower-roman">
										<li>
											IT Provider: oriented.net GmbH,
											Freie Strasse 12, 4001 Basel
										</li>
									</ol>
								</li>
								<li>
									We will take all necessary steps to ensure
									that your Personal Data is treated securely
									and in accordance with this Privacy Policy
									and applicable laws. All those contractors
									are required to sign contracts in which they
									promise to protect Personal Data using
									procedures similar to ours, unless otherwise
									provided for in this Privacy Policy.
								</li>
								<li>
									The purpose for sharing your personal data
									to the above-mentioned third-party is to
									support our business and to maintain and to
									constantly look to improve our Website and
									the services it offers to our users. The
									legal basis we rely on to process your
									personal data is article 6(1)(f) of the
									GDPR, which allows us to process personal
									data when its necessary for the purposes of
									our legitimate interests.
								</li>
							</ol>
						</li>
						<li>
							<h2>
								Transfer of your personal data with authorities
							</h2>
							<ol type="number-item">
								<li>
									We may also transfer your Personal Data
									(including your communications) if we think
									it’s necessary for security purposes, to
									investigate possible fraud or other
									violations of this Privacy Policy and/or
									attempts to harm other users of our Website
									or our dedicated pages on social media.
									Hence, we may share or transfer your
									Personal Data to investigate, respond to and
									resolve complaints and issues relating to
									our Website or our dedicated pages on social
									media.
								</li>
								<li>
									It is possible that we will need to disclose
									your Personal Data when required by law or
									if we have a good faith belief that
									disclosure is necessary to:
									<ol type="lower-roman">
										<li>
											investigate, prevent, or take action
											regarding suspected or actual
											illegal activities or to assist
											government enforcement agencies,
										</li>
										<li>
											enforce our agreements with you,
										</li>
										<li>
											investigate and defend ourselves
											against any third-party claims or
											allegations,
										</li>
										<li>
											protect the security or integrity of
											our Website or our dedicated pages
											on social media, or
										</li>
										<li>
											exercise or protect the rights and
											safety of our users, personnel, or
											others.
										</li>
									</ol>
								</li>
								<li>
									We attempt to notify users about legal
									demands for their Personal Data when
									appropriate in our judgment and technically
									feasible, unless prohibited by law or court
									order or when the request is an emergency.
									We may dispute such demands when we believe,
									in our discretion, that the requests are
									overbroad, vague or lack proper authority,
									but we do not promise to challenge every
									demand.
								</li>
								<li>
									The purpose for sharing your personal data
									to the above-mentioned authorities is to
									support our obligation within the meaning of
									article 6(1)(c) of the GDPR, which allows us
									to process personal data when its necessary
									for compliance with a legal obligation to
									which we are subject.
								</li>
							</ol>
						</li>
						<li>
							<h2>
								No Commercial Disclosure of your personal data
							</h2>
							<ol type="number-item">
								<li>
									We will not sell your Personal Data to third
									parties. We will not share or otherwise make
									available your Personal Data to third
									parties except as provided in this Privacy
									Policy.
								</li>
							</ol>
						</li>
						<li>
							<h2>How and where we store your personal data</h2>
							<ol type="number-item">
								<li>
									We are taking data protection issues
									seriously and we have chosen to store your
									Personal Data on Oriented.net GmbH’s servers
									which are located in Switzerland.
								</li>
							</ol>
						</li>
						<li>
							<h2>Cross-border data transfer</h2>
							<ol type="number-item">
								<li>
									For some of the third-party service
									providers, we may transfer your data to one
									of their databases outside Switzerland or
									the European Economic Area, potentially
									including countries which may not have an
									adequate level of protection for your
									Personal Data compared with that provided in
									Switzerland or the European Economic Area.
									In such event and unless provided otherwise
									in this Privacy Policy, we enter into
									agreements with such third-parties ensuring
									an adequate level of protection for your
									Personal Data.
								</li>
								<li>
									By providing us with your Personal Data or
									by accepting analytic cookies, you agree
									that we may transfer, store and process your
									Personal Data outside of Switzerland and the
									European Economic Area – in particular in
									the USA – and acknowledge that governments
									in certain countries such as the USA have
									broad powers to access data for security,
									crime prevention and detection and law
									enforcement purposes.
								</li>
							</ol>
						</li>
						<li>
							<h2>
								Protection of your personal data and data breach
								notification
							</h2>
							<ol type="number-item">
								<li>
									We exercise commercially reasonable efforts
									to prevent unauthorized exposure or
									disclosure of your Personal Data. In
									particular, we implement and maintain
									measures (comprising administrative,
									physical, and technical safeguards) for
									managing unauthorized disclosure or exposure
									of your Personal Data.
								</li>
								<li>
									In the event of a data breach, or in the
									event that we suspect a data breach, we will
									(i) do our best efforts to promptly notify
									you, where technically feasible, and (ii)
									cooperate with you to investigate and
									resolve the data breach, including without
									limitation by providing reasonable
									assistance to you in notifying injured
									third-parties. We will give you prompt
									access to such records related to a data
									breach as you may reasonably request;
									provided that we shall not be required to
									provide you with records belonging to, or
									compromising the security of, other users.
								</li>
								<li>
									In the event of a data breach, or in the
									event that we suspect a data breach, we will
									in addition notify the competent authorities
									in accordance with applicable law.
								</li>
							</ol>
						</li>
						<li>
							<h2>
								Management of your personal data (your rights)
							</h2>
							<ol type="number-item">
								<li>
									Right to access, update or to delete the
									information we have on you: Whenever made
									possible, you can access, update or request
									deletion of your Personal Data by contacting
									us at the following at:
									<a
										href="mailto:legal@superhuit.ch"
										data-target
										onMouseEnter={() => hoverOn()}
										onMouseLeave={() => hoverOff()}
									>
										{' '}
										legal@superhuit.ch
									</a>
									.
								</li>
								<li>
									Right of rectification: You have the right
									to have your Personal Data rectified if that
									information is inaccurate or incomplete.
								</li>
								<li>
									Right to object: You have the right to
									object to our processing of your Personal
									Data.
								</li>
								<li>
									Right of restriction: You have the right to
									request that we restrict the processing of
									your Personal Data.
								</li>
								<li>
									Right to data portability: You have the
									right to be provided with a copy of the
									Personal Data we have on you in a
									structured, machine-readable and commonly
									used format.
								</li>
								<li>
									Right to withdraw consent: You have the
									right to withdraw your consent at any time
									where we relied on your consent to process
									your Personal Data.
								</li>
								<li>
									Right to complain to an Authority: You have
									the right to complain to a data protection
									Authority about our collection and use of
									your Personal Data. For more information,
									please contact your local data protection
									authority in the European Economic Area
									(EEA) or in Switzerland.
								</li>
								<li>
									Please note that we may ask you to prove
									your identity before responding to requests
									based on the previous rights or somehow
									related to your Personal Data.
								</li>
							</ol>
						</li>
						<li>
							<h2>Retention of your personal data</h2>
							<ol type="number-item">
								<li>
									We retain the Personal Data you provide to
									the extent necessary to provide you access
									to and use of our Website or our dedicated
									pages on social media and of their
									functionalities (like for instance our
									newsletters), as well as to the extent
									necessary to comply with our legal
									obligations (for example, if we are required
									to retain your data to comply with
									applicable laws), resolve disputes, and
									enforce our legal agreements and policies.
								</li>
								<li>
									The storage period of cookies depends on
									their purpose and is the same for everyone.
								</li>
								<li>
									We may retain de-personalized (anonymous)
									information after the deletion of your
									Personal Data.
								</li>
							</ol>
						</li>
						<li>
							<h2>Security of your personal data</h2>
							<ol type="number-item">
								<li>
									The security of your Personal Data is
									important to us, but no method of
									transmission over the Internet, or method of
									electronic storage is 100% secure. While we
									strive to use technical and organizational
									commercially acceptable means to protect
									your Personal Data against manipulation,
									partial or complete loss and unauthorized
									access by third party, we cannot guarantee
									its absolute security.
								</li>
							</ol>
						</li>
						<li>
							<h2>Personal data relating to children</h2>
							<ol type="number-item">
								<li>
									We do not provide services directly to
									children or proactively collect their
									personal information.
								</li>
							</ol>
						</li>
						<li>
							<h2>Amendment of this privacy policy</h2>
							<ol type="number-item">
								<li>
									We may change this Privacy Policy at any
									time by posting a new version on this page
									or on a successor page, without prior
									notification. If we make changes to this
									Policy, we will notify you through a notice
									on the Website home page. By continuing to
									access or use our Website or our dedicated
									pages on social media after those revisions
									become effective, you agree to be bound by
									the revised terms. If you do not agree to
									the new terms, please stop using the Website
									and our dedicated pages on social media.
								</li>
								<li>
									However, we will always handle your Personal
									Data in accordance with the Privacy Policy
									that was in effect at the time of
									collection.
								</li>
								<li>
									The new version will become effective on the
									date it’s posted, which will be listed at
									the top of the page as the new effective
									date.
								</li>
							</ol>
						</li>
						<li>
							<h2>Miscellaneous</h2>
							<ol type="number-item">
								<li>
									Our failure to enforce any right or
									provision of this Policy will not be
									considered a waiver of those rights.
								</li>
								<li>
									If any provision of this Policy is held to
									be invalid or unenforceable by a court, the
									remaining provisions of this Policy will
									remain in effect.
								</li>
								<li>
									This Policy constitute the entire agreement
									between us regarding the Privacy Policy of
									our Website and our dedicated pages on
									social media, and supersede and replace any
									prior agreements we might have between us
									regarding the Website and our dedicated
									pages on social media.
								</li>
							</ol>
						</li>
						<li>
							<h2>Any question or complaint?</h2>
							<ol type="number-item">
								<li>
									Should you have questions or complaints
									regarding this Privacy Policy, please
									contact us online at
									<a
										href="mailto:legal@superhuit.ch"
										data-target
										onMouseEnter={() => hoverOn()}
										onMouseLeave={() => hoverOff()}
									>
										{' '}
										legal@superhuit.ch
									</a>
									.
								</li>
								<li>
									You can also reach us by physical mail at
									superhuit Sàrl, Rue des Terreaux 7, 1003
									Lausanne, Switzerland.
								</li>
								<li>
									Please further note that you have the right
									to lodge a complaint with a supervisory
									authority should you consider that your
									Personal Data is not processed in accordance
									with this Privacy Policy or in compliance
									with applicable regulations.
								</li>
							</ol>
						</li>
					</ol>

					<div className="footnote">
						<p>Last updated on June 16, 2021</p>
					</div>
				</section>

				<div className="page__background" data-animation-page></div>
			</div>
		</Layout>
	);
};

export default PrivacyPolicyPage;
